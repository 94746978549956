// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider} from "@chakra-ui/react";

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {Landing} from "./landing/Landing";
import { TwitchAuth } from './TwitchAuth';
import Quests from './quests/Quests';
import { QuestProvider } from './quests/context/QuestContext';
import {TermsOfService} from "./misc/TermsOfService";
import {PrivacyPolicy} from "./misc/PrivacyPolicy";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ChakraProvider>
      <RouterProvider router={createBrowserRouter([
        {
          path: '/',
          element: <Landing />,
        },
        {
          path: '/terms-of-service',
          element: <TermsOfService />
        },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicy />
        },
        {
          path: '/quests',
          element: (
            <QuestProvider>
              <Quests />
            </QuestProvider>
          ),
        },
        {
          path: 'twitch_oauth',
          element: <TwitchAuth />,
        }
      ])} />
    </ChakraProvider>
  </React.StrictMode>
);

// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();