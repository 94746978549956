import React, { useEffect } from 'react';
import {MapContainer, TileLayer, Marker, Polyline, useMap, Circle, LayerGroup} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { TaskOrder } from '../types';
import { Box, BoxProps } from '@chakra-ui/react';


const TILE_LAYER_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

interface MapProps extends BoxProps {
  taskOrders: TaskOrder[];
}

const FitBounds: React.FC<{ waypoints: L.LatLngExpression[] }> = ({ waypoints }) => {
  const map = useMap();
  useEffect(() => {
    if (waypoints.length > 0) {
      const bounds = L.latLngBounds(waypoints);
      map.fitBounds(bounds);
    }
  }, [map, waypoints]);
  return null;
};

const createCustomIcon = (taskName: string) => {
  return L.divIcon({
    html: `
      <div class="custom-marker-container" title="${taskName}">
        <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
          <circle cx="5" cy="5" r="5" fill="blue" />
        </svg>
        <div class="custom-label">
          ${taskName}
        </div>
      </div>
    `,
    className: '',
    iconSize: [10, 10],
    iconAnchor: [5, 5],
    popupAnchor: [0, -10]
  });
};

const Map: React.FC<MapProps> = ({ taskOrders, ...boxProps }) => {

  if(taskOrders.length === 0) {
    return <Box {...boxProps} />;
  }

  const waypoints = taskOrders.map(task => [task.latitude, task.longitude] as L.LatLngExpression);

  return (
    <Box {...boxProps}>
      <MapContainer style={{ width: '100%', height: '100%', position: 'relative' }} preferCanvas={true}>
        <TileLayer url={TILE_LAYER_URL} />
        <LayerGroup>
          {taskOrders.map((task, index) => (
            <Marker
              key={index}
              position={[task.latitude, task.longitude]}
              icon={createCustomIcon(task.name)}
            />
          ))}
        </LayerGroup>
        <LayerGroup>
          {taskOrders.map((task, index) => (
            <Circle
              key={index}
              center={[task.latitude, task.longitude]}
              radius={task.radiusKm * 1000}
            />
          ))}
        </LayerGroup>
        <LayerGroup>
          {taskOrders.map((task, index) => (
            <Marker
              key={index}
              position={[task.latitude, task.longitude]}
              icon={createCustomIcon(task.name)}
            />
          ))}
        </LayerGroup>
        <Polyline positions={waypoints} />
        <FitBounds waypoints={waypoints} />
      </MapContainer>
    </Box>
  );
};

export default Map;