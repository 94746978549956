import React, { createContext, useContext, useState, useCallback, useRef, useEffect, ReactNode } from 'react';
import { Quest, TaskOrder } from '../types';
import {
  getQuestsFromStorage,
  addQuestToStorage,
  updateQuestInStorage,
  deleteQuestFromStorage
} from '../utils/firebaseStorage';
import { v4 as uuidv4 } from 'uuid';
interface QuestContextProps {
  quests: Quest[];
  taskToEdit: Readonly<Partial<TaskOrder>>;
  isEditingExistTask: boolean;
  questNameToEdit: string;
  questDescriptionToEdit: string;
  questContextPromptToEdit: string;
  questFinishPromptToEdit: string;
  questTasksToEdit: TaskOrder[];
  publishedTasks: TaskOrder[];
  questIdToEdit: string | undefined;
  setQuestNameToEdit: (name: string) => void;
  setQuestDescriptionToEdit: (description: string) => void;
  setQuestContextPromptToEdit: (prompt: string) => void;
  setQuestFinishPromptToEdit: (prompt: string) => void;
  handlePublishQuest: () => void;
  handleDeleteQuest: (id: string) => void;
  handleSelectQuest: (id: string) => void;
  handleAddTask: (values: Omit<TaskOrder, 'id'>) => void;
  handleDeleteTask: (taskId: string) => void;
  handleEditTask: (task: TaskOrder) => void;
  handleSaveTask: (values: Omit<TaskOrder, 'id'>) => void;
  handleCancelEditing: () => void;
  handleAddNewQuest: () => void;
  moveTaskUp: (taskId: string) => void;
  moveTaskDown: (taskId: string) => void;
  questChanged: boolean;
}

interface QuestProviderProps {
  children: ReactNode;
}

const QuestContext = createContext<QuestContextProps | undefined>(undefined);

export const QuestProvider: React.FC<QuestProviderProps> = ({ children }) => {

  const isInitialized = useRef<boolean>(false);

  const constructEmptyQuest = () => ({ id: `${uuidv4()}`, tasks: [] } as Partial<Quest>);
  const constructEmptyTask = () => ({ id: `${uuidv4()}` } as Partial<TaskOrder>);

  const [questChanged, setQuestChanged] = useState<boolean>(false);
  const [quests, setQuests] = useState<Quest[]>([]);
  const [questIdToEdit, setQuestIdToEdit] = useState<string | undefined>();
  const [taskToEdit, setTaskToEdit] = useState<Readonly<Partial<TaskOrder>>>(constructEmptyTask());
  const [questDescriptionToEdit, setQuestDescriptionToEdit] = useState<string>('');
  const [questNameToEdit, setQuestNameToEdit] = useState<string>('');
  const [questContextPromptToEdit, setQuestContextPromptToEdit] = useState<string>('');
  const [questFinishPromptToEdit, setQuestFinishPromptToEdit] = useState<string>('');
  const [questTasksToEdit, setQuestTasksToEdit] = useState<TaskOrder[]>([]);
  const [questsLoaded, setQuestsLoaded] = useState<boolean>(false);
  const [publishedTasks, setPublishedTasks] = useState<TaskOrder[]>([]);
  const [isEditingExistTask, setIsEditingExistTask] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitialized.current) {
      void fetchQuests();
      isInitialized.current = true;
    }
  }, []);

  useEffect(()=>{
    const isEditingExistTask = taskToEdit && questTasksToEdit.some(task => task.id === taskToEdit.id);
    setIsEditingExistTask(isEditingExistTask);
  }, [taskToEdit, questTasksToEdit]);

  useEffect(() => {
    if(!!questIdToEdit) {
      const questToEdit = quests.find(quest => quest.id === questIdToEdit);
      if(!questToEdit) throw new Error(`Quest with id ${questIdToEdit} not found`);
      setQuestNameToEdit(questToEdit.name);
      setQuestDescriptionToEdit(questToEdit.description);
      setQuestContextPromptToEdit(questToEdit.contextPrompt);
      setQuestFinishPromptToEdit(questToEdit.finishPrompt);
      setQuestTasksToEdit(questToEdit.tasks);
      setPublishedTasks(questToEdit.tasks);
    } else {
      setQuestNameToEdit('');
      setQuestDescriptionToEdit('');
      setQuestContextPromptToEdit('');
      setQuestFinishPromptToEdit('');
      setQuestTasksToEdit([]);
      setPublishedTasks([]);
    }
  }, [questIdToEdit, quests]);

  const fetchQuests = async () => {
    const quests = await getQuestsFromStorage();
    setQuests(quests);
    setQuestsLoaded(true);
  }

  const handlePublishQuest = () => {
    const questToEdit = quests.find(quest => quest.id === questIdToEdit) ?? constructEmptyQuest();
    questToEdit.name = questNameToEdit;
    questToEdit.description = questDescriptionToEdit;
    questToEdit.contextPrompt = questContextPromptToEdit;
    questToEdit.finishPrompt = questFinishPromptToEdit;
    questToEdit.tasks = questTasksToEdit;
    const questToSave = questToEdit as Quest;
    if (questIdToEdit === undefined) {
      void addQuestToStorage(questToSave).then(
        async () => {
          await fetchQuests();
          setQuestIdToEdit(questToSave.id!);
        }
      );
    } else {
      void updateQuestInStorage(questToSave).then(
        () => fetchQuests()
      );
    }
    setQuestChanged(false);
  };

  const handleDeleteQuest = async (id: string) => {
    await deleteQuestFromStorage(id);
    await fetchQuests();
    setQuestIdToEdit('');
    setQuestChanged(false);
  };

  const handleAddNewQuest = () => {
    setQuestIdToEdit('');
  }

  const handleSelectQuest = (id: string) => {
    setQuestIdToEdit(id);
  };

  const syncTaskWithEdits = (taskToSave: TaskOrder, values: Omit<TaskOrder, "id">) => {
    return { id: taskToSave.id, ...values };
  }

  const handleAddTask = useCallback((values: Omit<TaskOrder, 'id'>) => {
    let taskToSave = constructEmptyTask() as TaskOrder;
    taskToSave = syncTaskWithEdits(taskToSave, values);
    setQuestTasksToEdit([...questTasksToEdit, taskToSave]);
    console.log('questTasksToEdit', questTasksToEdit);
    setTaskToEdit(constructEmptyTask());
    setQuestChanged(true);
  }, [questTasksToEdit, syncTaskWithEdits, setQuestTasksToEdit, setQuestChanged]);

  const handleSaveTask = useCallback((values: Omit<TaskOrder, 'id'>) => {
    setQuestTasksToEdit(prevTasks => prevTasks.map(task =>
      task.id === taskToEdit.id ? syncTaskWithEdits(task, values) : task
    ));
    setTaskToEdit(constructEmptyTask());
    setQuestChanged(true);
  }, [taskToEdit, syncTaskWithEdits]);

  const moveTaskUp = useCallback((taskId: string) => {
    setQuestTasksToEdit(prevTasks => {
      const index = prevTasks.findIndex(task => task.id === taskId);
      if (index > 0) {
        const newTasks = [...prevTasks];
        [newTasks[index - 1], newTasks[index]] = [newTasks[index], newTasks[index - 1]];
        return newTasks;
      }
      return prevTasks;
    });
    setQuestChanged(true);
  }, [questTasksToEdit]);

  const moveTaskDown = useCallback((taskId: string) => {
    setQuestTasksToEdit(prevTasks => {
      const index = prevTasks.findIndex(task => task.id === taskId);
      if (index < prevTasks.length - 1) {
        const newTasks = [...prevTasks];
        [newTasks[index + 1], newTasks[index]] = [newTasks[index], newTasks[index + 1]];
        return newTasks;
      }
      return prevTasks;
    });
    setQuestChanged(true);
  }, [questTasksToEdit]);

  const handleCancelEditing = useCallback(() => {
    setTaskToEdit(constructEmptyTask());
  }, []);

  const handleDeleteTask = useCallback((taskId: string) => {
    setQuestTasksToEdit(questTasksToEdit.filter(task => task.id !== taskId));
    setQuestChanged(true);
  }, [questTasksToEdit]);

  const handleEditTask = (task: TaskOrder) => {
    setTaskToEdit(task);
  };

  return (
    <QuestContext.Provider value={{
      quests,
      questIdToEdit,
      isEditingExistTask,
      taskToEdit,
      questNameToEdit,
      questDescriptionToEdit,
      questContextPromptToEdit,
      questFinishPromptToEdit,
      publishedTasks,
      // we need to set the questChanged state to true whenever we change the quest name, context prompt, or finish prompt,
      // but for tasks we only need to set it to true when we add or delete a task, editing task fields doesn't go to quest immediately.
      setQuestNameToEdit: (name: string) => {
        setQuestNameToEdit(name);
        setQuestChanged(true);
      },
      setQuestDescriptionToEdit: (description: string) => {
        setQuestDescriptionToEdit(description);
        setQuestChanged(true);
      },
      setQuestContextPromptToEdit: (prompt: string) => {
        setQuestContextPromptToEdit(prompt);
        setQuestChanged(true);
      },
      setQuestFinishPromptToEdit: (prompt: string) => {
        setQuestFinishPromptToEdit(prompt);
        setQuestChanged(true);
      },
      moveTaskUp,
      moveTaskDown,
      handlePublishQuest,
      handleDeleteQuest,
      handleSelectQuest,
      handleAddTask,
      questTasksToEdit,
      handleDeleteTask,
      handleEditTask,
      handleSaveTask,
      handleCancelEditing,
      handleAddNewQuest,
      questChanged,
    }}>
      {children}
    </QuestContext.Provider>
  );
};

export const useQuestContext = () => {
  const context = useContext(QuestContext);
  if (!context) {
    throw new Error('useQuestContext must be used within a QuestProvider');
  }
  return context;
};