import firebase from 'firebase/compat/app';
import initializeApp = firebase.initializeApp;

export const firebaseApp = initializeApp({
  projectId: 'avimate-8048c',
  storageBucket: 'avimate-8048c.appspot.com',
  apiKey: 'AIzaSyBL1T04z-s207BBkp_nXnBR37knzpui7AI',
  authDomain: 'avimate-8048c.firebaseapp.com',
  messagingSenderId: '610140154114',
  appId: '1:610140154114:web:62d3d1d85d0136f0693a1b',
  measurementId: 'G-TPQNWS2L4P',
});