import React from 'react';
import { VStack, HStack, Select, Button, Input, Textarea, Divider } from '@chakra-ui/react';
import {useQuestContext} from './context/QuestContext';
import TaskForm from './components/TaskForm';
import TaskDisplay from './components/TaskDisplay';
import Map from "./components/Map";

const Quests: React.FC = () => {
  const {
    questChanged,
    quests,
    questIdToEdit,
    isEditingExistTask,
    questNameToEdit,
    questDescriptionToEdit,
    questContextPromptToEdit,
    questFinishPromptToEdit,
    taskToEdit,
    questTasksToEdit,
    publishedTasks,
    setQuestNameToEdit,
    setQuestDescriptionToEdit,
    setQuestContextPromptToEdit,
    setQuestFinishPromptToEdit,
    handlePublishQuest,
    handleDeleteQuest,
    handleSelectQuest,
    handleAddNewQuest,
  } = useQuestContext();

  return (
    <VStack spacing={4} w="full" p="4">
      <HStack w="full">
        <VStack w="full">
          <Select
            w="100%"
            placeholder="New Quest"
            value={questIdToEdit ?? undefined}
            onChange={(e) => handleSelectQuest(e.target.value)}
          >
            {quests.map(quest => (
              <option key={quest.id} value={quest.id}>{quest.name}</option>
            ))}
          </Select>
          <Input placeholder="Quest Name" value={questNameToEdit} onChange={(e) => setQuestNameToEdit(e.target.value)} />
          <Textarea w="full" placeholder="Quest Description" value={questDescriptionToEdit} onChange={(e) => setQuestDescriptionToEdit(e.target.value)} />
          <HStack w="full">
            <Textarea w="full" placeholder="Context Prompt" value={questContextPromptToEdit} onChange={(e) => setQuestContextPromptToEdit(e.target.value)} />
            <Textarea w="full" placeholder="Finish Prompt" value={questFinishPromptToEdit} onChange={(e) => setQuestFinishPromptToEdit(e.target.value)} />
          </HStack>
        </VStack>
        {questTasksToEdit.length > 0 && <Map w="400px" h="265px" taskOrders={publishedTasks} />}
      </HStack>
      <HStack ml="auto">
        {!!questIdToEdit && (
          <Button ml="auto" onClick={() => handleDeleteQuest(questIdToEdit)}>Delete Quest</Button>
        )}
        <Button colorScheme={questChanged ? 'orange' : 'gray'} onClick={handlePublishQuest}>Publish Quest</Button>
        {!!questIdToEdit && <Button onClick={handleAddNewQuest}>Add New Quest</Button>}
      </HStack>
      <Divider w="full" />
      {questTasksToEdit.map(task => (
        task.id === taskToEdit.id ? <TaskForm key={task.id} /> : <TaskDisplay key={task.id} task={task} />
      ))}
      {!isEditingExistTask && <TaskForm />}
    </VStack>
  );
};

export default Quests;