import React from 'react';
import {HStack, Text, Button, Box, VStack} from '@chakra-ui/react';
import {TaskOrder} from '../types';
import {useQuestContext} from '../context/QuestContext';

interface TaskDisplayProps {
  task: TaskOrder;
}

const TaskDisplay: React.FC<TaskDisplayProps> = ({task}) => {
  const {handleEditTask, handleDeleteTask, moveTaskDown, moveTaskUp} = useQuestContext();

  return (
    <HStack alignItems="start" w="full" p={4} borderWidth={1} borderRadius="md" spacing={2} bg="lightblue">
      {task.image && <Box w="100px" h="100px" bg="lightgray" mt="1">
          <img src={task.image} alt="task"
               style={{objectFit: 'cover', maxWidth: '100px', maxHeight: '100px', width: '100px', height: '100px'}}/>
      </Box>}
      <VStack flexGrow={1} minWidth={0}>
        <HStack w="full">
          <Text fontWeight="bold">{task.name}</Text>

          <Button ml="auto" size="xs" onClick={() => moveTaskUp(task.id)}>▲</Button>
          <Button size="xs" onClick={() => moveTaskDown(task.id)}>▼</Button>
        </HStack>
        <HStack w='full'>
          <Text fontWeight={600}>target:</Text>
          <Text>{task.longitude} lon.</Text>
          <Text>{task.latitude} lat.</Text>
          <Text>in {task.radiusKm} km radius</Text>
          {task.useTeleport && (
            <>
              <Text fontWeight={600}>teleport:</Text>
              <Text>{task.teleportLongitude} lon.</Text>
              <Text>{task.teleportLatitude} lat.</Text>
              <Text>{task.teleportAltitude} alt.</Text>
              <Text>{task.teleportHeading}° true</Text>
              <Text>{task.teleportSpeed} knots</Text>
            </>
          )}
        </HStack>
        <Box w="full" p={2} borderWidth={1} borderRadius="md" bg="lightblue">
          <Text isTruncated fontWeight='500'>{task.prompt}</Text>
        </Box>
        <Box ml="auto">
          <Button onClick={() => handleEditTask(task)} mr={2}>Edit</Button>
          <Button onClick={() => handleDeleteTask(task.id)}>Delete</Button>
        </Box>
      </VStack>
    </HStack>
  );
};

export default TaskDisplay;