import React, {useEffect} from 'react';
import { VStack, HStack, Input, Textarea, Button, Text, Checkbox } from '@chakra-ui/react';
import { useQuestContext } from '../context/QuestContext';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {TaskOrder} from "../types";

const validationSchema = yup.object().shape({
  taskNameToEdit: yup.string().required('Task Name is required'),
  taskPromptToEdit: yup.string().required('Task Prompt is required'),
  taskLatitudeToEdit: yup.number().required('Latitude is required').typeError('Latitude must be a number').min(-90, 'Latitude must be between -90 and 90').max(90, 'Latitude must be between -90 and 90'),
  taskLongitudeToEdit: yup.number().required('Longitude is required').typeError('Longitude must be a number').min(-180, 'Longitude must be between -180 and 180').max(180, 'Longitude must be between -180 and 180'),
  taskRadiusKmToEdit: yup.number().required('Radius is required').typeError('Radius must be a number').min(0, 'Radius must be at least 0'),
  taskImageToEdit: yup.string().test('is-url', 'Image URL must be a valid URL', (value, _this) => {
    if(value === undefined) return true;
    const urlPattern = /^(http|https):\/\/[^ "]+$/;
    return urlPattern.test(value);
  }),
  taskUseTeleportToEdit: yup.boolean().required(),
  teleportation: yup.object().when('taskUseTeleportToEdit', {
    is: true,
    then: schema => schema.shape({
      taskTeleportLongitudeToEdit: yup.number().optional().typeError('Latitude must be a number').min(-180, 'Longitude must be between -180 and 180').max(180, 'Longitude must be between -180 and 180'),
      taskTeleportLatitudeToEdit: yup.number().optional().typeError('Latitude must be a number').min(-90, 'Latitude must be between -90 and 90').max(90, 'Latitude must be between -90 and 90'),
      taskTeleportAltitudeToEdit: yup.number().optional().typeError('Altitude must be a number').min(0, 'Altitude must be at least 0'),
      taskTeleportHeadingToEdit: yup.number().optional().typeError('Heading must be a number').min(0, 'Heading must be between 0 and 360').max(360, 'Heading must be between 0 and 360'),
      taskTeleportSpeedToEdit: yup.number().optional().typeError('Speed must be a number in knots').min(0, 'Speed must be at least 0'),
    }).test('at-least-one', 'At least one teleportation parameter is required', (value, _this) => {
      const { taskUseTeleportToEdit } = _this.parent;
      const isTeleportationDataValid = taskUseTeleportToEdit === false ||
        (
          !isNaN(value.taskTeleportLongitudeToEdit ?? NaN) ||
          !isNaN(value.taskTeleportLatitudeToEdit ?? NaN) ||
          !isNaN(value.taskTeleportAltitudeToEdit ?? NaN) ||
          !isNaN(value.taskTeleportHeadingToEdit ?? NaN) ||
          !isNaN(value.taskTeleportSpeedToEdit ?? NaN)
        );
      console.log('isTeleportationDataValid', isTeleportationDataValid);
      return isTeleportationDataValid;
    }),
    otherwise: schema => schema.notRequired()
  }),
});

const TaskForm: React.FC = () => {
  const {
    taskToEdit,
    isEditingExistTask,
    handleAddTask,
    handleSaveTask,
    handleCancelEditing
  } = useQuestContext();


  const formik = useFormik({
    initialValues: {
      taskNameToEdit: taskToEdit?.name ?? '',
      taskPromptToEdit: taskToEdit?.prompt ?? '',
      taskLatitudeToEdit: taskToEdit?.latitude ?? NaN,
      taskLongitudeToEdit: taskToEdit?.longitude ?? NaN,
      taskRadiusKmToEdit: taskToEdit?.radiusKm ?? NaN,
      taskImageToEdit: taskToEdit?.image ?? '',
      taskUseTeleportToEdit: taskToEdit?.useTeleport ?? false,
      teleportation: {
        taskTeleportLongitudeToEdit: taskToEdit?.teleportLongitude ?? NaN,
        taskTeleportLatitudeToEdit: taskToEdit?.teleportLatitude ?? NaN,
        taskTeleportAltitudeToEdit: taskToEdit?.teleportAltitude ?? NaN,
        taskTeleportHeadingToEdit: taskToEdit?.teleportHeading ?? NaN,
        taskTeleportSpeedToEdit: taskToEdit?.teleportSpeed ?? NaN,
      }
    },
    validationSchema,
    onSubmit: (values) => {
      const newTaskOrder: Omit<TaskOrder, 'id'> = {
        name: values.taskNameToEdit,
        prompt: values.taskPromptToEdit,
        latitude: values.taskLatitudeToEdit,
        longitude: values.taskLongitudeToEdit,
        radiusKm: values.taskRadiusKmToEdit,
        image: values.taskImageToEdit,
        useTeleport: values.taskUseTeleportToEdit,
        teleportLatitude: values.teleportation.taskTeleportLatitudeToEdit,
        teleportLongitude: values.teleportation.taskTeleportLongitudeToEdit,
        teleportAltitude: values.teleportation.taskTeleportAltitudeToEdit,
        teleportHeading: values.teleportation.taskTeleportHeadingToEdit,
        teleportSpeed: values.teleportation.taskTeleportSpeedToEdit,
      };
      if (isEditingExistTask) {
        handleSaveTask(newTaskOrder);
      } else {
        handleAddTask(newTaskOrder);
      }
    },
  });

  const handleFormSubmit = () => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length > 0) {
        console.log("Errors", errors);
        const errorMessages = Object.values(errors).flatMap(error =>
          typeof error === 'object' ? Object.values(error).join('\n') : error
        ).join('\n');
        alert(`Please fix the following errors:\n${errorMessages}`);
      } else {
        formik.handleSubmit();
      }
    });
  };

  return (
    <form style={{width: "100%"}} onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
      <VStack w="full" p={4} borderWidth={1} borderRadius="md" spacing={2} bg="lightskyblue">
        <Input
          bg="white"
          w="full"
          placeholder="Task Name"
          value={formik.values.taskNameToEdit}
          onChange={formik.handleChange}
          name="taskNameToEdit"
          borderColor={formik.errors.taskNameToEdit ? 'red.500' : 'inherit'}
        />
        <Textarea
          bg="white"
          placeholder="Task Prompt"
          value={formik.values.taskPromptToEdit}
          onChange={formik.handleChange}
          name="taskPromptToEdit"
          borderColor={formik.errors.taskPromptToEdit ? 'red.500' : 'inherit'}
        />
        <HStack w="full">
          <HStack w={formik.values.taskUseTeleportToEdit ? "40%" : "full"}>
            <Text>TARGET:</Text>
            <Input
              bg="white"
              placeholder="Longitude"
              type="number"
              value={formik.values.taskLongitudeToEdit}
              onChange={formik.handleChange}
              name="taskLongitudeToEdit"
              borderColor={formik.errors.taskLongitudeToEdit ? 'red.500' : 'inherit'}
            />
            <Input
              bg="white"
              placeholder="Latitude"
              type="number"
              value={formik.values.taskLatitudeToEdit}
              onChange={formik.handleChange}
              name="taskLatitudeToEdit"
              borderColor={formik.errors.taskLatitudeToEdit ? 'red.500' : 'inherit'}
            />
            <Input
              w="300px"
              bg="white"
              placeholder="Radius (km)"
              type="number"
              value={formik.values.taskRadiusKmToEdit}
              onChange={formik.handleChange}
              name="taskRadiusKmToEdit"
              borderColor={formik.errors.taskRadiusKmToEdit ? 'red.500' : 'inherit'}
            />
          </HStack>
          <HStack w={formik.values.taskUseTeleportToEdit ? "60%" : "unset"}>
            <Text>TELEPORT:</Text>
            <Checkbox
              isChecked={formik.values.taskUseTeleportToEdit}
              onChange={formik.handleChange}
              name="taskUseTeleportToEdit"
            />
            {formik.values.taskUseTeleportToEdit && (
              <HStack>
                <Input
                  bg="white"
                  placeholder="Longitude"
                  type="number"
                  value={formik.values.teleportation.taskTeleportLongitudeToEdit}
                  onChange={formik.handleChange}
                  name="teleportation.taskTeleportLongitudeToEdit"
                  borderColor={formik.errors.teleportation?.taskTeleportLongitudeToEdit ? 'red.500' : 'inherit'}
                />
                <Input
                  bg="white"
                  placeholder="Latitude"
                  type="number"
                  value={formik.values.teleportation.taskTeleportLatitudeToEdit}
                  onChange={formik.handleChange}
                  name="teleportation.taskTeleportLatitudeToEdit"
                  borderColor={formik.errors.teleportation?.taskTeleportLatitudeToEdit ? 'red.500' : 'inherit'}
                />
                <Input
                  bg="white"
                  placeholder="Altitude (feet)"
                  type="number"
                  value={formik.values.teleportation.taskTeleportAltitudeToEdit}
                  onChange={formik.handleChange}
                  name="teleportation.taskTeleportAltitudeToEdit"
                  borderColor={formik.errors.teleportation?.taskTeleportAltitudeToEdit ? 'red.500' : 'inherit'}
                />
                <Input
                  bg="white"
                  placeholder="Heading (true)"
                  type="number"
                  value={formik.values.teleportation.taskTeleportHeadingToEdit}
                  onChange={formik.handleChange}
                  name="teleportation.taskTeleportHeadingToEdit"
                  borderColor={formik.errors.teleportation?.taskTeleportHeadingToEdit ? 'red.500' : 'inherit'}
                />
                <Input
                  bg="white"
                  placeholder="Speed (knots)"
                  type="number"
                  value={formik.values.teleportation.taskTeleportSpeedToEdit}
                  onChange={formik.handleChange}
                  name="teleportation.taskTeleportSpeedToEdit"
                  borderColor={formik.errors.teleportation?.taskTeleportSpeedToEdit ? 'red.500' : 'inherit'}
                />
              </HStack>
            )}
          </HStack>
        </HStack>
        <HStack w="full">
          <Input
            bg="white"
            w="full"
            placeholder="Image URL"
            value={formik.values.taskImageToEdit}
            onChange={formik.handleChange}
            name="taskImageToEdit"
            borderColor={formik.errors.taskImageToEdit ? 'red.500' : 'inherit'}
          />
          {isEditingExistTask && <Button onClick={handleCancelEditing}>Cancel</Button>}
          <Button type="submit">
            {isEditingExistTask ? 'Save Task' : 'Add Task'}
          </Button>
        </HStack>
      </VStack>
    </form>
  );
};

export default TaskForm;