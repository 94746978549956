import {HStack, VStack, Text, Image } from "@chakra-ui/react"
import { Footer } from "./landing/Footer"
import aviLogoBmp from "./landing/images/logo512-p-500.png";
import skyBackgroundBmp from "./landing/images/sky3-p-1600.png";

export const TwitchAuth = () => {

  const searchParams = new URLSearchParams(window.location.search);
  const code = searchParams.get("code");

  return (
    <VStack
      w="full"
      gap={0}
      h='calc(100vh)'
      backgroundImage={skyBackgroundBmp}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <HStack gap="0" w="full" flexGrow="0" p="6" position="absolute">
        <Image w="90px" src={aviLogoBmp}/>
        <Text
          fontSize={{base: "5xl", sm: "6xl"}}
          textShadow="2px 3px 0px #000000"
          fontWeight="bold"
          fontFamily="Nourd-Bold"
          color="white"
        >
          AviMate
        </Text>
      </HStack>
      <VStack w="full" h="xp00" p="20px" alignItems="center" justifyContent="center" flexGrow="1">
          <Text size="md" color="white" textShadow="1px 1px 0px #000000">Copy this auth code into AviMate app:</Text>
          <Text
            fontSize={{base: "5xl", sm: "6xl"}}
            color="white"
            textShadow="2px 3px 0px #000000"
            fontFamily="Nourd-Bold"
          >{code}</Text>
      </VStack>
      <Footer />
    </VStack>
  )
}