import {getFirestore, collection, addDoc, getDocs, doc, updateDoc, deleteDoc} from 'firebase/firestore/lite';
import {Quest} from '../types';
import {firebaseApp} from "./firebaseConfig";

const store = getFirestore(firebaseApp);

export const getQuestsFromStorage = async (): Promise<Quest[]> => {
  const questsCollection = collection(store, 'quests');
  const querySnapshot = await getDocs(questsCollection);
  console.log('querySnapshot', querySnapshot);
  return querySnapshot.docs.map(doc => doc.data() as Quest);
};

export const addQuestToStorage = async (quest: Quest): Promise<void> => {
  const questsCollection = collection(store, 'quests');
  await addDoc(questsCollection, quest);
};

export const updateQuestInStorage = async (updatedQuest: Quest): Promise<void> => {
  const questsCollection = collection(store, 'quests');
  const querySnapshot = await getDocs(questsCollection);
  const questDocId = querySnapshot.docs.find(doc => doc.data().id === updatedQuest.id)?.id;
  if (questDocId !== undefined) {
    const questDoc = doc(store, 'quests', questDocId);
    await updateDoc(questDoc, updatedQuest);
  } else {
    throw new Error(`Quest with id ${updatedQuest.id} not found`);
  }
};

export const deleteQuestFromStorage = async (questId: string): Promise<void> => {
  const questsCollection = collection(store, 'quests');
  const querySnapshot = await getDocs(questsCollection);
  const questDocId = querySnapshot.docs.find(doc => doc.data().id === questId)?.id;
  if (questDocId !== undefined) {
    const questDoc = doc(store, 'quests', questDocId);
    await deleteDoc(questDoc);
  } else {
    throw new Error(`Quest with id ${questId} not found`);
  }
};